import { doc, getDoc, updateDoc,onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { FaChevronRight } from "react-icons/fa6";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { firestore } from '../../firebase';

const SettingsSections = () => {
    const { uid } = useParams();
    const [settings, setSettings] = useState({});
    const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const navigate = useNavigate();
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [uid]);
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const userDocRef = doc(firestore, 'users', uid);
                const docSnap = await getDoc(userDocRef);
                if (docSnap.exists()) {
                    const userSettings = docSnap.data();
                    setSettings(userSettings);
                    setSelectedLanguage(userSettings.language || 'English');
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchSettings();
    }, [uid]);

    const handleToggle = async (settingName) => {
        try {
            const updatedSettings = { ...settings, [settingName]: !settings[settingName] };
            const userDocRef = doc(firestore, 'users', uid);
            await updateDoc(userDocRef, updatedSettings);
            setSettings(updatedSettings);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    const handleLanguageChange = async (language) => {
        try {
            const updatedSettings = { ...settings, language };
            const userDocRef = doc(firestore, 'users', uid);
            await updateDoc(userDocRef, { language });
            setSettings(updatedSettings);
            setSelectedLanguage(language);
            setLanguageDropdownVisible(false);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    const privacyNavigate = () => {
        navigate(`/privacypolicy/${uid}`);
    }

    const termsNavigate = () => {
        navigate(`/termsanduse/${uid}`);
    }

    return (
        <>
            <div className='w-full max-w-[454px] flex flex-col px-[10px] sm:px-[20px] pb-[70px] lg:pb-[30px] pt-[100px] py-[30px]'>
                <div className='mt-0 md:mt-[40px] text-[15px] leading-[18px] font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'إعدادات الإشعار' : 'Notification Settings'}</div>
                <div className='w-full p-[15px] rounded-[10px] bg-white settingDiv mt-[10px]'>
                    <div className='w-full flex items-center justify-between emailLower'>
                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'اشعارات البريد الالكتروني' : 'Email Notifications'}</div>
                        <label className="switch">
                            <input type="checkbox" checked={settings.emailNotification} onChange={() => handleToggle('emailNotification')} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className='w-full flex items-center justify-between emailLower'>
                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'دفع الإخطارات' : 'Push Notifications'}</div>
                        <label className="switch">
                            <input type="checkbox" checked={settings.pushNotification} onChange={() => handleToggle('pushNotification')} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className='w-full flex items-center justify-between emailLower'>
                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'إشعارات التعليق' : 'Comment Notifications'}</div>
                        <label className="switch">
                            <input type="checkbox" checked={settings.commentNotification} onChange={() => handleToggle('commentNotification')} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className='w-full flex items-center justify-between emailLower'>
                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'يحب الإخطارات' : 'Likes Notifications'}</div>
                        <label className="switch">
                            <input type="checkbox" checked={settings.likesNotification} onChange={() => handleToggle('likesNotification')} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className='mt-[20px] text-[15px] leading-[18px] font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'إعدادات الخصوصية' : 'Privacy Settings'}</div>
                <div className='w-full px-[15px] rounded-[10px] bg-white settingDiv mt-[10px]'>
                    <div className='w-full flex items-center justify-between emailLower'>
                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'حساب خاص' : 'Private Account'}</div>
                        <label className="switch">
                            <input type="checkbox" checked={settings.privateProfile} onChange={() => handleToggle('privateProfile')} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className='mt-[20px] text-[15px] leading-[18px] font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'اعدادات اللغة' : 'Language Settings'}</div>
                <div className='w-full px-[15px] rounded-[10px] bg-white settingDiv mt-[10px] relative'>
                    <div className='w-full flex items-center justify-between emailLower cursor-pointer' onClick={() => setLanguageDropdownVisible(!languageDropdownVisible)}>
                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'لغة' : 'Language'} ({selectedLanguage})</div>
                        <img src="https://cdn-icons-png.flaticon.com/128/2115/2115307.png" alt="" width="20px" />
                    </div>
                    {languageDropdownVisible && (
                        <div className='absolute top-full mt-2 w-full bg-white border rounded shadow-lg z-10'>
                            <div className='p-2 cursor-pointer hover:bg-gray-100' onClick={() => handleLanguageChange('English')}>English</div>
                            <div className='p-2 cursor-pointer hover:bg-gray-100' onClick={() => handleLanguageChange('Arabic')}>Arabic</div>
                        </div>
                    )}
                </div>
                <div className='mt-[20px] text-[15px] leading-[18px] font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'الخصوصية والشروط' : 'Privacy & Terms'}</div>
                <div className='w-full py-[10px] px-[15px] rounded-[10px] bg-white settingDiv mt-[10px]'>
                    <button onClick={privacyNavigate} className='w-full flex items-center justify-between '>
                        <Link className='w-full flex items-center justify-between emailLower'>
                            <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'سياسة الخصوصية' : 'Privacy Policy'}</div>
                            <FaChevronRight className='text-[13px] text-[#000000]' />
                        </Link>
                    </button>
                    <button onClick={termsNavigate} className='w-full flex items-center justify-between'>
                        <Link className='w-full flex items-center justify-between emailLower'>
                            <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'شروط الاستخدام' : 'Terms of Use'}</div>
                            <FaChevronRight className='text-[13px] text-[#000000]' />
                        </Link>
                    </button>
                </div>
            </div>
        </>
    )
}

export default SettingsSections
