import React, { useEffect, useState } from 'react'
import '../Styles/Styles.css'
import { IoIosCamera } from "react-icons/io";
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore, storage } from '../firebase';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../loader';


const Signup = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedOption, setSelectedOption] = useState('Personal');
    const [UserName, setUserName] = useState('Jhon');
    const [Gender , setGender] = useState('Male');
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState(null);
    const [savingData, setSavingData] = useState(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file); 
    };

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectGender = (event) => {
        setGender(event.target.value);
    }
    const handleSelectUserName = (event) => {
        setUserName(event.target.value);
    }
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserEmail(user.email);
            }
        });
        return () => unsubscribe();
    }, []);
    const saveUserData = async () => {
        setSavingData(true);
        const currentUser = auth.currentUser;
    
        if (currentUser && selectedImage) {
            let isValid = true;
            let nameToSave;
    
            if (selectedOption === 'Personal') {
                if (!UserName || !document.getElementById('name') || !document.getElementById('name').value) {
                    isValid = false;
                } else {
                    nameToSave = document.getElementById('name').value;
                }
            } else if (selectedOption === 'Company') {
                if (!UserName || !document.getElementById('company') || !document.getElementById('company').value) {
                    isValid = false;
                } else {
                    nameToSave = document.getElementById('company').value;
                }
            }
    
            if (isValid) {
                try {
                    // Check if the username or name already exists
                    const usersCollection = collection(firestore, 'users');
                    const usernameQuery = query(usersCollection, where('username', '==', document.getElementById('username').value));
                    const nameQuery = query(usersCollection, where('name', '==', nameToSave));
    
                    const usernameSnapshot = await getDocs(usernameQuery);
                    const nameSnapshot = await getDocs(nameQuery);
    
                    if (!usernameSnapshot.empty) {
                        toast.error('Username already exists. Try a different one.');
                        setSavingData(false);
                        return;
                    }
                    if ( !nameSnapshot.empty) {
                        toast.error('Name already exists. Try a different one.');
                        setSavingData(false);
                        return;
                    }
                   
    
                    const imageRef = ref(storage, `users/${currentUser.uid}/profileImage.jpg`);
                    const snapshot = await uploadBytes(imageRef, selectedImage);
                    const downloadURL = await getDownloadURL(snapshot.ref);
                    const dobValue = document.getElementById('dob').value;

                    const userData = {
                        // username: currentUser.displayName,
                        bio:"",
                        commentNotification:false,
                        emailNotification:false,
                        imageURL: downloadURL,
                        accountType: selectedOption,
                        gender: Gender,
                        name: document.getElementById('username').value,
                        uid: currentUser.uid,
                        email: userEmail,
                        joiningDate: new Date(),
                        date: selectedOption === 'Personal' && dobValue ? new Date(dobValue) : "",
                        // formationDate: selectedOption === 'Company' ? document.getElementById('formation').value : null,
                        username: nameToSave,
                        requests: [],
                        block:[],
                        followers:[],
                        following:[],
                        language:"English",
                        likesNotification:false,
                        privateProfile:false,
                        pushNotification:false,
                        token:"",
                        posts:[],
                    };
    
                    const userDocRef = doc(firestore, 'users', currentUser.uid);
                    await setDoc(userDocRef, userData);
    
                    console.log("User data saved successfully!");
                    navigate(`/home/${currentUser.uid}`);
                } catch (error) {
                    console.error("Error saving user data: ", error);
                    toast.error("Error saving user data");
                } finally {
                    setSavingData(false);
                }
            } else {
                console.error("Please fill in all required fields.");
                toast.error('Please fill in all required fields.');
                setSavingData(false);
            }
        }
    };
    
    return (
        <>
          <Toaster
          position='top-center'
          />
                         {savingData && <Loader/>}

            <div className='flex flex-col w-full overflow-hidden justify-center items-center relative min-h-[100vh]'>
                <div className='flex items-center flex-col px-[15px] py-[30px] md:p-0'>
                    <img src={require('../Assets/bluelogo.png')} className='w-[150px] h-[50px] md:w-[198px] md:h-[86px]' alt="" />
                    <div className='relative mt-[30px]'>
                        {selectedImage ? (
                            <img src={URL.createObjectURL(selectedImage)} className='relative z-1 w-[100px] md:w-[130px] h-[100px] md:h-[130px] rounded-full' alt="" />
                        ) : (
                            <img src={require('../Assets/user.png')} className='relative z-1 w-[100px] md:w-[130px] h-[100px] md:h-[130px] rounded-full' alt="" />
                        )}

                        <div className='absolute z-2 bottom-[15px] right-0'>
                            <label className=''>
                                <input type="file" accept="image/*" onChange={handleImageChange} />
                                <div className='w-[25px]  md:w-[31px] bg-white h-[25px] md:h-[31px] rounded-full flex items-center justify-center'>
                                    <div className='w-[90%] rounded-full h-[90%] bg-[#2C2C2E] flex justify-center items-center'>
                                        <IoIosCamera className='text-[13px] md:text-[16px] text-[white]' />
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className='flex flex-col mt-[30px]'>
                        <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>Select Account Type</div>
                        <Form.Select
                            aria-label="Default select example"
                            className='accountSelect'
                            value={selectedOption}
                            onChange={handleChange}
                        >
                            <option value="Personal">Personal</option>
                            <option value="Company">Company</option>
                        </Form.Select>
                    </div>
                    <div className='flex flex-col mt-[15px]'>
                        <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>User name</div>
                        <input type="text" className='nameInput' placeholder='User Name' id='username' />

                    </div>
                    {selectedOption === 'Personal' && (<div className='flex flex-col'>
                        <div className='flex flex-col mt-[15px]'>
                            <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>Your Name</div>
                            <input type="text" className='nameInput' placeholder='Enter here' id='name' />
                        </div>
                        <div className='flex flex-col mt-[15px]'>
                            <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>What’s your Gender?</div>
                            <Form.Select aria-label="Default select example" className='accountSelect' value={Gender} onChange={handleSelectGender}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </Form.Select>
                        </div>
                        <div className='flex flex-col mt-[15px]'>
                            <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>Your Date of Birth?</div>
                            <input type="date" className='nameInput' id='dob' />
                        </div>
                    </div>)}
                    {selectedOption === 'Company' && (<div className='flex flex-col'>
                        <div className='flex flex-col mt-[15px]'>
                            <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>Company Name</div>
                            <input type="text" className='nameInput' placeholder='Enter here' id='company' />
                        </div>
                        <div className='flex flex-col mt-[15px]'>
                            <div className='montserratFamily text-[16px] md:text-[20px] text-[#000000] font-semibold'>Formation Date?</div>
                            <input type="date" className='nameInput' id='formation' />
                        </div>
                    </div>)}
                    <button onClick={saveUserData}>
                    <Link  className='flex items-center justify-center w-[270px] md:w-[318px] h-[40px] md:h-[46px] rounded-[7px] border-none bg-[#3BA7FF] mt-[30px] montserratFamily text-[15px] text-white'>Next</Link>

                    </button>
                </div>
            </div>
        </>
    )
}

export default Signup
