import React, { useState, useEffect } from 'react';
import { FaPen } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc,onSnapshot } from 'firebase/firestore';
import EmailDetail from './EmailDetail';
import ComposeEmail from './ComposeEmail';
import { Link } from 'react-router-dom';
import { firestore } from '../../firebase';
import { format } from 'date-fns';
import Loader from '../../loader';
import { Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const EmailInbox = () => {
    const [showEmails, setShowEmails] = useState(true);
    const [displayDetail, setDisplayDetail] = useState(false);
    const [showCompose, setShowCompose] = useState(false);
    const [filteredInbox, setFilteredInbox] = useState([]);
    const { uid } = useParams();
    const [selectedEmail, setSelectedEmail] = useState(null); // Track selected email
    const [senderUID, setSenderUID] = useState(null); // Track sender UID
    const [savingData, setSavingData] = useState(false);

    useEffect(() => {
        const fetchEmails = async () => {
            setSavingData(true);
            try {
                // Query emails where the recieverUID is equal to the given uid
                const recieverQuery = query(collection(firestore, 'emails'), where('recieverUID', '==', uid));
                const senderQuery = query(collection(firestore, 'emails'), where('senderUID', '==', uid));
                
                // Execute both queries
                const [recieverSnapshot, senderSnapshot] = await Promise.all([
                    getDocs(recieverQuery),
                    getDocs(senderQuery)
                ]);

                // Combine results from both queries
                const emails = [];

                recieverSnapshot.forEach((doc) => {
                    emails.push({ ...doc.data(), id: doc.id });
                });

                senderSnapshot.forEach((doc) => {
                    // Avoid duplicate entries by checking if the email is already in the list
                    if (!emails.some(email => email.id === doc.id)) {
                        emails.push({ ...doc.data(), id: doc.id });
                    }
                });
                emails.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());

                // Fetch sender details for each email
                const updatedEmails = await Promise.all(emails.map(async (email) => {
                    const senderDocRef = doc(firestore, 'users', email.senderUID);
                    const senderDocSnap = await getDoc(senderDocRef);
                    if (senderDocSnap.exists()) {
                        const senderData = senderDocSnap.data();
                        return { ...email, senderData };
                    } else {
                        console.error("Sender document not found:", email.senderUID);
                        return email; // If sender document not found, return original email
                    }
                }));

                setFilteredInbox(updatedEmails);
                setSavingData(false);
            } catch (error) {
                console.error('Error fetching emails: ', error);
                setSavingData(false);
            }
        };
        
        fetchEmails();
    }, [uid]);
    
    
    useEffect(() => {
        console.log(filteredInbox);
    }, [filteredInbox]);
    

    const showDetail = (email) => {
        setSelectedEmail(email);
        setDisplayDetail(true);
        setShowEmails(false);
    };

    // Function to toggle email detail display
    const toggleDisplayDetail = () => {
        setSelectedEmail(null); // Clear selected email
        setDisplayDetail(false);
        setShowEmails(true);
        setShowCompose(false);
    };

    const handleShowCompose = () => {
        setShowEmails(false);
        setDisplayDetail(false);
        setShowCompose(true);
    };

    // Function to set sender UID
    const setSender = (senderUID) => {
        setSenderUID(senderUID);
    };

    const formatTime = (timestamp) => {
        // Check if timestamp is null or undefined
        if (!timestamp) {
            return "";
        }
    
        // Convert Firestore timestamp to JavaScript Date object
        const date = timestamp.toDate();
    
        // Format the date
        return format(date, "hh:mm a");
    };
    const truncateDescription = (description) => {
        const words = description.split(" ");
        if (words.length > 8) {
            return words.slice(0, 11).join(" ") + "...";
        }
        return description;
    };


    return (
        <>
               {savingData && <Loader/>}

            <div className='w-full items-center flex flex-col px-[10px] pb-[70px] lg:pb-[30px] sm:px-[20px] pt-[100px] py-[30px]'>
                <div className='flex flex-col pb-[60px] pt-[30px] sm:pt-[50px]  px-[10px] sm:px-[20px] bg-white rounded-[16px] w-full lg:max-w-[500px]'>
                    {showEmails && <div className='flex items-center justify-between px-[30px] w-full'>
                        <div className='masterFamily text-[#000000] text-[17px]  leading-[18px] mb-[10px]'>Inbox</div>
                        <button onClick={handleShowCompose} className='flex items-center justify-center w-[97px] h-[33px] bg-[#3BA7FF] rounded-[28px] border-none text-white text-[12px] font-semibold montserratFamily'>
                            <FaPen className='text-[11px] text-[white] mr-[3px]' />
                            Compose
                        </button>
                    </div>}
                    {showEmails && <div className='flex w-full flex-col'>
                        {filteredInbox.map((item, index) => (
                            <Link key={index} state={{ emailDisplayed: item }}  onClick={() => showDetail(item)} className='flex items-center justify-between mt-[20px]'>
                                <div className='flex items-center gap-[8px]'>
                                    <img src={item.senderData.imageURL} className='w-[63px] h-[63px] rounded-full' alt="" />
                                    <div className='flex flex-col'>
                                        <div className='text-[13px] leading-[15px] text-[#000000] font-semibold montserratFamily'>{item.senderData.username}</div>
                                        <div className='text-[10px] leading-[12px] text-[#000000] montserratFamily'><strong>{item.subject ? item.subject : "No Subject"}</strong></div>
                                        <div className='text-[10px] leading-[12px] text-[#808080] montserratFamily'>{truncateDescription(item.description)}
                                        </div>
                                    </div>
                                </div>
                                <div className='text-[10px] leading-[12px] text-[#A6A6A6] font-semibold flex'>
                                {formatTime(item.timestamp)}
                               
                                </div>
                            </Link>
                        ))}
                    </div>}
                    {displayDetail && <EmailDetail toggleDisplayDetail={toggleDisplayDetail} toggleCompose={handleShowCompose} />}
                    {showCompose && <ComposeEmail toggleDisplayDetail={toggleDisplayDetail} senderUID={uid} replyEmail={selectedEmail} />}
                </div>
            </div>
        </>
    );
};

export default EmailInbox;
