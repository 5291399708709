import React, { useState, useEffect, useRef } from 'react';
import bee from "../../Assets/bee.png";
import tree from "../../Assets/tree.png";

const NoInternetPopup = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [difficulty, setDifficulty] = useState('medium');
  const [score, setScore] = useState(0);
  const canvasRef = useRef(null);
  let dinoY = 100;
  let dinoVelocity = 0;
  let gravity = 0.4;
  let isJumping = false;
  let obstacles = [];
  let obstacleSpeed = 3;
  const dinoWidth = 40;
  const dinoHeight = 40;
  const treeWidth = 40;
  const treeHeight = 45;

  const resetGame = () => {
    setGameOver(false);
    setGameStarted(true);
    setScore(0);
    dinoY = 100;
    dinoVelocity = 0;
    isJumping = false;
    obstacles = [];
    obstacleSpeed = difficulty === 'easy' ? 2 : difficulty === 'hard' ? 4 : 3;
  };

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!isOnline && gameStarted && !gameOver) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const beeImage = new Image();
      const treeImage = new Image();
      beeImage.src = bee;
      treeImage.src = tree;

      const jump = () => {
        if (!isJumping) {
          dinoVelocity = -10;
          isJumping = true;
        }
      };

      const handleKeyDown = (e) => {
        if (e.code === 'Space') {
          jump();
        }
      };

      const generateObstacles = () => {
        const randomHeight = Math.floor(Math.random() * 50) + 30;
        const randomWidth = Math.floor(Math.random() * 20) + 10;
        obstacles.push({ x: canvas.width, width: randomWidth, height: randomHeight, passed: false });
      };

      const gameLoop = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Dino physics
        dinoY += dinoVelocity;
        dinoVelocity += gravity;

        if (dinoY > 100) {
          dinoY = 100;
          isJumping = false;
        }

        // Draw Bee (Dino)
        ctx.drawImage(beeImage, 50, dinoY, dinoWidth, dinoHeight);

        // Obstacles
        obstacles.forEach((obstacle, index) => {
          obstacle.x -= obstacleSpeed;
          ctx.drawImage(treeImage, obstacle.x, canvas.height - obstacle.height, treeWidth, obstacle.height);

          // Improved collision detection
          const beeLeft = 50; // Dino's X position (constant)
          const beeRight = beeLeft + dinoWidth;
          const beeTop = dinoY;
          const beeBottom = dinoY + dinoHeight;

          const treeLeft = obstacle.x;
          const treeRight = treeLeft + treeWidth;
          const treeTop = canvas.height - obstacle.height;

          // Check if bee (dino) collides with tree
          if (
            beeRight > treeLeft && beeLeft < treeRight && // Horizontal overlap
            beeBottom > treeTop && beeTop < canvas.height // Vertical overlap
          ) {
            setGameOver(true);
            setGameStarted(false);
          }

          // Check if obstacle has passed the dino
          if (!obstacle.passed && obstacle.x + obstacle.width < beeLeft) {
            obstacle.passed = true;
            setScore(prevScore => prevScore + 1); // Increment score
          }

          if (obstacle.x + obstacle.width < 0) {
            obstacles.splice(index, 1); // Remove passed obstacles
          }
        });

        if (gameStarted && !gameOver) {
          requestAnimationFrame(gameLoop);
        }
      };

      // Add event listener for space bar to jump
      window.addEventListener('keydown', handleKeyDown);

      // Start generating obstacles every 2 seconds
      const obstacleInterval = setInterval(generateObstacles, 2000);

      // Start the game loop
      gameLoop();

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        clearInterval(obstacleInterval);
      };
    }
  }, [gameStarted, isOnline, gameOver]);

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  if (isOnline) {
    return null; // Don't show the popup if the user is online
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-semibold mb-4">No Internet Connection</h2>
        <p className="mb-6">Please make sure you are connected to the internet.</p>
        <button
          onClick={handleReload}
          className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-700 mb-4"
        >
          Reload
        </button>
        <div className="mb-4">
          <canvas ref={canvasRef} width={400} height={150} className="border" />
        </div>

        {gameOver && (
          <div>
            <p className="text-red-500 font-bold mb-4">Game Over! Try again.</p>
            <p className="mb-4">Score: {score}</p> {/* Display score */}
            <button
              onClick={resetGame}
              className="px-4 py-2 bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-700"
            >
              Restart Game
            </button>
          </div>
        )}

        {!gameStarted && !gameOver && (
          <>
            <label className="block mb-4">
              Difficulty:
              <select value={difficulty} onChange={handleDifficultyChange} className="ml-2">
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </label>

            <button
              onClick={() => setGameStarted(true)}
              className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-700"
            >
              Start Game
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default NoInternetPopup;
