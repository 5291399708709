import React, { useState, useEffect } from 'react';
import { CiImageOn } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { useParams } from 'react-router-dom';
import { firestore, storage } from '../../firebase'; // Import your Firebase configuration
import { addDoc, collection, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Loader from '../../loader';
import toast, { Toaster } from 'react-hot-toast';

const PostSection = () => {
    const [postText, setPostText] = useState('');
    const [postImages, setPostImages] = useState([]);
    const { uid } = useParams();
    const [savingData, setSavingData] = useState(false);
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const fetchUserLanguage = async () => {
            try {
                const userDocRef = doc(firestore, 'users', uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setLanguage(userData.language || 'English');
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        fetchUserLanguage();
    }, [uid]);

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        const newImages = Array.from(files);
        setPostImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleDeleteImage = (index) => {
        const newImages = [...postImages];
        newImages.splice(index, 1);
        setPostImages(newImages);
    };

    const handlePost = async () => {
        setSavingData(true);
        try {
            const imageUrls = [];

            // Upload images to Firebase Storage
            for (const image of postImages) {
                const storageRef = ref(storage, `images/${uid}/${Date.now()}_${image.name}`);
                await uploadBytes(storageRef, image);
                const url = await getDownloadURL(storageRef);
                imageUrls.push(url);
            }

            // Save post data including image URLs to Firestore
            const docRef = await addDoc(collection(firestore, 'posts'), {
                description: postText,
                postImageURL: imageUrls, // Save image URLs instead of image data
                uid: uid,
                timestamp: new Date(),
                comment: [],
                language: language, // Save the selected language
                likes: [],
                reports:[],
            });

            await updateDoc(docRef, {
                id: docRef.id
            });

            console.log("Post added successfully with ID: ", docRef.id);

            const userDocRef = doc(collection(firestore, 'users'), uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                // Update user document with new post ID
                await updateDoc(userDocRef, {
                    posts: arrayUnion(docRef.id) // Add the new post ID to the 'posts' array
                });
            }

            // Clear the form after posting
            setPostText('');
            setPostImages([]);
            setSavingData(false);
            toast.success('Post added Successfully');
        } catch (error) {
            console.error("Error adding post: ", error);
            setSavingData(false);
            toast.error('Failed to add post');
        }
    };

    return (
        <>
            <Toaster position='top-center' />
            {savingData && <Loader />}
            <div className='w-full flex flex-col items-center px-[10px] sm:px-[20px] pt-[100px] pb-[70px] lg:pb-[20px] py-[30px]'>
                <div className='mb-[15px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col '>
                    <div className='text-[18px] leading-[21px] text-center text-[#000000] font-bold montserratFamily'>
                        {language === 'Arabic' ? 'إنشاء منشور' : 'Create Post'}
                    </div>
                    <p className={`postWish ${language === 'Arabic' ? 'arabic' : ''}`}>
                        {language === 'Arabic' ? 'أتمنى...' : ' I Wish...'}
                    </p>
                    <textarea
                        className='postTextArea'
                        placeholder={language === 'Arabic' ? 'ماذا يدور في ذهنك؟' : 'What`s on your mind?'}
                        value={postText}
                        onChange={(e) => setPostText(e.target.value)}
                        dir={language === 'Arabic' ? 'rtl' : 'ltr'}
                    ></textarea>
                    <div className='mt-[30px] w-full h-[1px] bg-[#DFDFDF]'></div>
                    <div className='w-full pt-[10px] h-fit flex justify-start items-end flex-wrap gap-[8px]'>
                        <label className='w-[60px] h-[60px] md:w-[86px] md:h-[86px] rounded-[10px] flex items-center justify-center bg-[#F0F0F0]'>
                            <input type="file" accept="image/*" onChange={handleFileInputChange} multiple />
                            <CiImageOn className='text-[27px] text-[#BFBFBF]' />
                        </label>
                        {postImages.map((item, index) => (
                            <div key={index} className='w-[60px] h-[60px] md:w-[86px] md:h-[86px] relative'>
                                <img src={URL.createObjectURL(item)} className='w-full h-full rounded-[10px] relative z-1' alt="" />
                                <div onClick={() => handleDeleteImage(index)} className='w-[15px] md:w-[18px] cursor-pointer h-[15px] md:h-[18px] rounded-full  bg-[#FF0037] flex items-center justify-center absolute top-[4px] right-[4px] z-2'>
                                    <RxCross2 className='text-[8px] md:text-[10px] text-white' />
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handlePost}
                        className='w-[250px] md:w-[300px] h-[42px] md:h-[48px] rounded-[28px] mt-[20px] mb-[20px] bg-[#3BA7FF] font-semibold border-none mx-auto montserratFamily text-[15px] text-white'
                    >
                        {language === 'Arabic' ? 'نشر' : 'Post'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default PostSection;
