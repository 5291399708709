import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCjshVnQVro1cyxfoEMfbNSJIsiqsSvSmM",
  authDomain: "wishes-481a3.firebaseapp.com",
  projectId: "wishes-481a3",
  storageBucket: "wishes-481a3.appspot.com",
  messagingSenderId: "720819420935",
  appId: "1:720819420935:web:8b50d277c1a78b1f074fa2"
};



const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
export { app, analytics, firestore,storage,auth };