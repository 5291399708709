import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { auth, firestore, storage } from '../../firebase';
import { collection, doc, getDoc, getDocs, onSnapshot, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Loader from '../../loader';
import toast, { Toaster } from 'react-hot-toast';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const EditprofileComponent = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showGenderField, setShowGenderField] = useState(true);
    const {uid} = useParams();
    const [savingData, setSavingData] = useState(false);
    const [language, setLanguage] = useState('English');
    const [inputDate, setInputDate] = useState(
        userData && userData.date && userData.date.seconds 
            ? new Date(userData.date.seconds * 1000).toISOString().split('T')[0] 
            : ''
    );

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        return () => unsubscribe();
    }, [uid]);

    useEffect(() => {
        const fetchUserData = async () => {
            setSavingData(true);
            try {
                const userDocRef = doc(firestore, 'users', uid); 
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const data = userDocSnap.data();
                    setUserData(data);

                    // Convert Firestore timestamp to date string if exists
                    if (data.date && data.date.seconds) {
                        const fetchedDate = new Date(data.date.seconds * 1000).toISOString().split('T')[0];
                        setInputDate(fetchedDate);
                    }
                    
                    setSavingData(false);
                } else {
                    console.log('User document does not exist');
                    setSavingData(false);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setSavingData(false);
            }
        };
    
        if (uid) {
            fetchUserData();
        }
    }, [uid, firestore]);
    useEffect(() => {
        if (userData && userData.accountType === 'Company') {
            setShowGenderField(false);
        } else {
            setShowGenderField(true);
        }
    }, [userData]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage2(file);

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleNameChange = (event) => {
        setUserData({ ...userData, name: event.target.value });
    };
    const handleUserNameChange = (event) => {
        setUserData({ ...userData, username: event.target.value });
    };
    const handleEmailChange = (event) => {
        setUserData({ ...userData, email: event.target.value });
    };

    const handleBioChange = (event) => {
        setUserData({ ...userData, bio: event.target.value });
    };


const handleSave = async () => {
    setSavingData(true);
    try {
        // Fetch the original user data
        const userDocRef = doc(firestore, 'users', uid);
        const userDocSnap = await getDoc(userDocRef);
        
        if (!userDocSnap.exists()) {
            toast.error('User document does not exist');
            setSavingData(false);
            return;
        }
        
        const originalUserData = userDocSnap.data();

        // Validate required fields like name or username if needed
        if (!userData.name || !userData.username) {
            toast.error('Name and Username are required.');
            setSavingData(false);
            return;
        }

        // (Validation checks for name and username...)

        let imageURL = userData.imageURL; // Default to existing image URL
        if (selectedImage2) {
            // If a new image is selected, upload it and get the URL
            try {
                const storageRef = ref(storage, `images/${uid}`);
                const snapshot = await uploadBytes(storageRef, selectedImage2);
                imageURL = await getDownloadURL(snapshot.ref);
            } catch (uploadError) {
                toast.error('Error uploading image');
                console.error('Image upload failed:', uploadError);
                setSavingData(false);
                return;
            }
        }

        // Handle date conversion
        let updatedDate = userData.date; // Default to existing date
        if (inputDate) {
            const newDate = new Date(inputDate); // Parse the date input
            if (!isNaN(newDate.getTime())) {
                updatedDate = Timestamp.fromDate(newDate); // Convert to Firestore Timestamp
            } else {
                toast.error('Invalid date format');
                setSavingData(false);
                return;
            }
        }

        // Update userData with the new imageURL and converted date
        const userDataToUpdate = { ...userData, imageURL, date: updatedDate };

        // Update user document
        await updateDoc(userDocRef, userDataToUpdate);

        console.log('User data updated successfully');
        setSavingData(false);
        toast.success('Profile updated successfully');
    } catch (error) {
        console.error('Error updating user data:', error);
        setSavingData(false);
        toast.error('Error updating data');
    }
};



    const handleDateInput = (event) => {
        const inputDate = new Date(event.target.value);
        if (!isNaN(inputDate)) {
          // Date is valid
          const isoDate = inputDate.toISOString();
          // Do something with isoDate
        } else {
          console.error('Invalid Date');
        }
      };
      
    
    return (
        <>
        <Toaster position='top-center'/>
         {savingData && <Loader/>}

            <div className='w-full items-center flex flex-col px-[10px] lg:px-[20px] pb-[70px] lg:pb-[30px] pt-[100px] py-[30px]'>
                <div className='flex flex-col pb-[60px] md:pb-[30px] pt-[30px] md:pt-[50px] px-[20px] bg-white rounded-[16px] w-full lg:max-w-[474px]'>
                    <div className='text-[19px] leading-[21px] text-center font-semibold text-[#000000] montserratFamily'>{language === 'ds' ? 'تعديل الملف الشخصي' : 'Edit Profile'}</div>
                    <div className='w-full h-[1px] bg-[#EDEDED] mt-[15px]'></div>
                    <div className='flex flex-col items-center mt-[20px] gap-[10px]'>
                        {selectedImage ? (
                            <img src={selectedImage} className='w-[92px] h-[92px] rounded-full' alt="" />
                        ) : (
                            <img src={userData?.imageURL} className='w-[92px] h-[92px] rounded-full' alt="" />
                        )}
                        <label className='text-[13px] leading-[15px] font-semibold text-[#3BA7FF] montserratFamily'>
                            <input type="file" accept="image/*" onChange={handleImageChange} />
                            Change Photo
                        </label> 
                    </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px] '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'اسمك' : 'Your Name'}</div>
                        <input dir={language === 'ds' ? 'rtl' : 'ltr'} type="text" className='w-full h-[41px] md:h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder={userData && userData.accountType === 'Company' ? userData.companyName : userData && userData.name}
                          value={ userData?.name || ''} onChange={handleNameChange} />
                    </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px] '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'اسم المستخدم' : 'User Name'}</div>
                        <input dir={language === 'ds' ? 'rtl' : 'ltr'} type="text" className='w-full h-[41px] md:h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder={ userData?.username}
                          value={ userData?.username || ''} readOnly />
                    </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'بريد إلكتروني' : 'Email'}</div>
                        <input dir={language === 'ds' ? 'rtl' : 'ltr'} type="email " className='w-full h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder={userData?.email}
                          value={userData?.email || ''} onChange={handleEmailChange} />
                    </div>
                    {showGenderField && userData && userData.accountType === 'personal' && (
                <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                    <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>Gender</div>
                    <Form.Select aria-label="Default select example" className='genderSelect'>
                        <option value="1">Female</option>
                        <option value="2">Male</option>
                    </Form.Select>
                </div>
            )}
            <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>
                    {userData && userData.accountType === 'Company' ? (language === 'ds' ? 'تاريخ التشكيل' : 'Formation Date') : (language === 'ds' ? 'تاريخ الميلاد' : 'Date of Birth')}
                </div>
                <input 
    type="date"
    dir={language === 'ds' ? 'rtl' : 'ltr'}
    className='w-full h-[51px] editnameInput rounded-[28px] border-none mt-[8px] pl-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' 
    value={inputDate}
    onChange={(e) => {
        const selectedDate = e.target.value; // Capture selected date
        setInputDate(selectedDate); // Update input date as the user types
        setUserData({
            ...userData,
            date: selectedDate // Temporarily store the string format in userData
        });
    }}
    onBlur={() => {
        if (inputDate) {
            const newDate = new Date(inputDate); // Convert to JS Date object
            if (!isNaN(newDate.getTime())) {
                const timestamp = Timestamp.fromDate(newDate); // Convert to Firestore Timestamp
                setUserData({
                    ...userData,
                    date: timestamp // Update userData with Firestore Timestamp
                });
            } else {
                toast.error('Invalid date format');
            }
        }
    }}
/>


  
            </div>
                    <div className='mx-auto mt-[15px] flex flex-col w-[300px] md:w-[328px]  '>
                        <div className='text-[10px] pl-[10px] leading-[12px] text-[#000000] font-semibold montserratFamily'>{language === 'ds' ? 'السيرة الذاتية' : 'Bio'}</div>
                        <textarea dir={language === 'ds' ? 'rtl' : 'ltr'} type="date" className='w-full h-[100px] md:h-[130px] editBio rounded-[28px] border-none mt-[8px] p-[15px] bg-[#F6F6F6] text-[13px] md:text-[15px] leading-[18px] text-[#000000] font-semibold montserratFamily' placeholder='Enter here'
                        value={userData?.bio || ''} onChange={handleBioChange} ></textarea>
                    </div>
                    <button onClick={handleSave} className='w-[300px] md:w-[328px]  h-[51px] mx-auto rounded-[28px] border-none mt-[15px] montserratFamily bg-[#3BA7FF] text-[15px] font-semibold text-white'>Save</button>
                </div>
            </div>
        </>
    )
}

export default EditprofileComponent
